import React from 'react'
import axios from "axios";
import { baseURL } from '../utils/constant';

function List({id, task, setUpdateUI, updateMode}) {
  const removeTask=()=>{
    axios.delete(`${baseURL}/delete/${id}`).then((res)=>{
      console.log(res);
      setUpdateUI((prevState)=>!prevState)
    })
  }
  return (
    <li>{task}
    <div><button onClick={()=>updateMode(id,task)}>Edit</button> <button onClick={removeTask}>Delete</button></div>
    </li>
  )
}

export default List