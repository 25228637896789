import React,{useState,useEffect} from 'react'
import List from './List';
import Home from './Home';
import axios from "axios";
import { baseURL } from '../utils/constant';
function CrudData() {
  const [input,setinput]=useState("");
  const [tasks,setTasks]=useState([]);
  const [updateUI,setUpdateUI]=useState(false);
  const [updateID,setUpdateID]=useState(null);

  useEffect(()=>{
    axios.get(`${baseURL}/get`).then((res)=>{
      console.log(res.data);
      setTasks(res.data);
    });
  },[updateUI]);

  const addTask =()=>{
    axios.post(`${baseURL}/save`,{task:input}).then((res)=>{
      console.log(res.data);
      setinput("");
      setUpdateUI((prevState)=>!prevState);
    });
  }

  const updateMode=(id,text)=>{
    console.log(text);
    setinput(text);
    setUpdateID(id);
  }
const updateTask=()=>{
 axios.put(`${baseURL}/update/${updateID}`,{task:input}).then((res)=>{
  console.log(res.data);
  setUpdateUI((prevState)=>!prevState);
  setUpdateID(null);
  setinput("");
 })
}
  return (
    <main>
      <Home/>
      <h1>CRUD Operations auth</h1>
      <div>
        <input
        type='text'
        value={input}
        onChange={(e)=>setinput(e.target.value)}
        />
        <button onClick={updateID?updateTask:addTask} type='submit'>
          {updateID?"Update Task":"Add Task"}
          </button>
      </div>
      <ul>
        {tasks.map((task)=>(
          <List key={task._id} id={task._id} 
          task={task.task} 
          setUpdateUI={setUpdateUI}
          updateMode={updateMode}/>
        ))}
       </ul>
    </main>
  )
}

export default CrudData