import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CrudData from './components/CrudData'
import Login from './components/Login'
import Signup from "./components/Signup"
//import Home from './components/Home';
function App() {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<Login/>}/>
          <Route path="/signup" element={<Signup/>}/>
          <Route path="/home" element={<CrudData/>}/>
        </Routes>
      </Router>
  )
}

export default App